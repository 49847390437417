import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
    sm: "360px",
    md: "768px",
    lg: "1024px",
    xl: "1440px"
});

const theme = { 
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    breakpoints,
    fonts: {
        body: "source-sans-pro, sans-serif",
        heading: "source-sans-pro, sans-serif"
    },
    colors: {
        steel: {
            '50':  '#f4f8f9',
            '100': '#daf1fa',
            '200': '#afe2f4',
            '300': '#7ac4e3',
            '400': '#43a0cc',
            '500': '#317fb4',
            '600': '#29659b',
            '700': '#004f7e',
            '800': '#193356',
            '900': '#0f1f38',
          },
        shadow: {
            '50':  '#f8f9f7',
            '100': '#f2f2f2',
            '200': '#dfdadd',
            '300': '#bdb7ba',
            '400': '#978f92',
            '500': '#7a6f70',
            '600': '#645455',
            '700': '#4d3f41',
            '800': '#352c2e',
            '900': '#221c1e',
          },
},
    styles: {
        global: {
            a: {
                color: 'steel.700',
                _hover: {
                    textDecoration: 'none',
                    color: 'steel.500'
                }
            },
            p: {
                marginBottom: '1.5rem'
            },
            h1: {
                color: 'steel.700',
                fontWeight: '400'
            },
            h2: {
                color: 'steel.700',
                size: {
                    sm: '2rem',
                    md: '2.25rem'
                }
            },
            h3: {
                color: 'steel.700',
                fontWeight: '600',
                fontSize: '1.5rem',
                marginBottom: '1rem'
            },
            h4: {
                color: 'shadow.900',
                fontWeight: '600',
                fontSize: '1.5rem',
                marginBottom: '1rem'
            },
            h5: {
                color: 'shadow.900',
                fontWeight: '600',
                fontSize: '1.5rem'
            }
        }
    },
    components: {
        Text: {
            marginBottom: '1.5rem',
            variants: {
                "sub-head": {
                    color: 'steel.700',
                    fontSize: '1rem',
                    textTransform: 'uppercase'
                }
            }
        },
        Heading: {
            variants: {
                "page-title": {
                    fontSize: '4rem',
                    fontWeight: '600'
                },
                "sub-title": {
                    fontSize: '2rem',
                    fontWeight: '100'
                }
            }
        },
        Link: {
            base: {
                color: 'steel.700',
                _hover: {
                    textDecoration: 'none',
                    color: 'steel.500'
                }
            },
            variants: {
                "nav-item": {
                    color: "steel.700",
                    fontSize: "1.25rem",
                    textTransform: "uppercase",
                    fontWeight: 700,
                    _hover: "steel.500"
                },
                "footerNav": {
                    color: 'white !important',
                    _hover: {
                        textDecoration: 'none',
                        color: 'steel.500'
                    }
                },
                "tech-menu": {
                    color: "steel.700",
                    fontSize: '1.25rem',
                    _hover: {
                        textDecoration: 'none',
                        color: 'steel.500'
                    }
                }
            }
        },
        Button: {
            variants: {
                "ctaButtonBlue": {
                    borderRadius: '50px',
                    border: '2px',
                    borderColor: 'steel.700',
                    bg:'steel.700',
                    color: 'white',
                    _hover: { bg: 'steel.500', borderColor: 'steel.500' },
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    padding: '10px 25px 10px 25px'
                },
                "ctaButtonOutline": {
                    borderRadius: '50px',
                    border: '2px',
                    borderColor: 'steel.700',
                    bg:'white ',
                    color: 'steel.700',
                    _hover: { bg: 'steel.500', borderColor: 'steel.500', color: 'white' },
                    fontWeight: '600',
                    textTransform: 'uppercase',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    padding: '10px 25px 10px 25px'
                }
            }
        },
        Tabs: {
            variants: {
                "verticalTabs": {
                    borderInlineStartWidth: '10px',
                    justifContent: "left" 
                }
            } 
        }
    }

}

export default extendTheme(theme)