// Provide Chakra as high in the React component tree as possible
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'

import theme from './theme'
import React from 'react'

// const config = {
//     initialColorMode: "dark",
//     useSystemColorMode: false,
// }

// const theme = extendTheme({ config })

export const wrapPageElement = ({ element }) => {
    return (
        <ChakraProvider resetCSS theme={theme}>
           {/* <Fonts /> */}
            <ColorModeScript
                initialColorMode={theme.config.initialColorMode}
                key="chakra-ui-no-flash"
                />
           
                {element}
        </ChakraProvider>
    )
}

