/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

 import { wrapPageElement as wrap } from './src/root-wrapper'

 export const wrapPageElement = wrap

//  const addScript = url => {
//     const script = document.createElement("script")
//     script.src = url
//     script.async = true
//     document.body.appendChild(script)
//   }
  
//   export const onClientEntry = () => {
//     window.onload = () => {
//       addScript("https://boards.greenhouse.io/embed/job_board/js?for=rippleneurotechventures")
//     }
//   }

